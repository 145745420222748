import React from 'react';
import useInternetStatus from '../../hooks/useInternetStatus';
import { OfflineOverlay } from '../../styledComponents';

function Offline() {
  const isOnline = useInternetStatus();

  if (isOnline) return null;

  return (
    <OfflineOverlay>
      <p>Du är offline. </p>
      <p>Kontrollera din internetanslutning.</p>
    </OfflineOverlay>
  );
}

export default Offline;
