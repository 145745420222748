import { useState, useEffect } from 'react';

function useInternetStatus(timeoutMs = 3000) {
  const [isOnline, setIsOnline] = useState(true); // Assume online initially

  useEffect(() => {
    const checkConnectivity = async () => {
      try {
        const controller = new AbortController();
        const timeout = setTimeout(() => controller.abort(), timeoutMs);

        // Use the current page's domain and root URL for the ping
        const homeUrl = `${window.location.origin}/`;

        const response = await fetch(homeUrl, {
          method: 'HEAD', // Minimal request
          cache: 'no-cache', // Ensure fresh check
          signal: controller.signal
        });

        clearTimeout(timeout);

        if (response.ok) {
          setIsOnline(true);
        } else {
          setIsOnline(false);
        }
      } catch (error) {
        setIsOnline(false);
      }
    };

    const handleOnline = () => {
      setIsOnline(true);
      checkConnectivity();
    };
    const handleOffline = () => setIsOnline(false);

    // Initial check
    checkConnectivity();

    // Add event listeners
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [timeoutMs]);

  return isOnline;
}

export default useInternetStatus;
